import React from 'react';
import { useSelector } from 'react-redux';
import { CommonRootState } from 'ecto-common/lib/reducers/storeCommon';
import { getEquipmentNameFromTraits } from 'ecto-common/lib/utils/equipmentTypeUtils';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import styles from './ProcessMap.module.css';
import ProcessMapHeaderColumn from 'ecto-common/lib/ProcessMap/ProcessMapHeaderColumn';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import T from 'ecto-common/lib/lang/Language';
import {
  nodeIsEquipment,
  useCurrentNode
} from 'ecto-common/lib/hooks/useCurrentNode';

const ProcessMapEquipmentHeader = () => {
  const equipmentTypes = useSelector(
    (state: CommonRootState) => state.general.equipmentTypes
  );

  const { currentNode: equipment } = useCurrentNode();

  if (equipment && nodeIsEquipment(equipment)) {
    const equipmentTypeText = getEquipmentNameFromTraits(
      equipment.nodeTraits,
      equipmentTypes
    );

    return (
      <PlainBox className={styles.equipmentInfo}>
        <ProcessMapHeaderColumn
          title={T.equipment.equipmenttype}
          subtitle={equipmentTypeText}
        />
        <ProcessMapHeaderColumn
          title={T.equipment.description}
          subtitle={
            !isNullOrWhitespace(equipment.description) ? (
              <CopyToClipboardTooltip valueToCopy={equipment.description}>
                {' '}
                {equipment.description}
              </CopyToClipboardTooltip>
            ) : (
              T.equipment.nodescription
            )
          }
        />
      </PlainBox>
    );
  }

  return null;
};

export default React.memo(ProcessMapEquipmentHeader);
