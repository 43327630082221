import React, { useCallback, useMemo } from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import { formatDate } from 'js/utils/dateUtils';
import { getNodeName } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { ExportOptionsType } from 'js/components/ExportData/ExportOptions';
import APIGen, { ExportFormat } from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import { useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

interface ConfirmExportSignalsDialogProps {
  isOpen?: boolean;
  data: ExportOptionsType;
  onModalClose: () => void;
  onExportCompleted?(): void;
}

const ConfirmExportSignalsDialog = ({
  isOpen,
  data,
  onModalClose,
  onExportCompleted
}: ConfirmExportSignalsDialogProps) => {
  const exportMutation = APIGen.Signals.addSignalExports.useMutation({
    onSuccess: () => {
      onModalClose();
      onExportCompleted?.();
      toastStore.addSuccessToast(T.exportpage.exportdatasuccess);
    },
    onError: () => {
      toastStore.addErrorToast(T.exportpage.exportdatafailed);
    }
  });

  const nodeIds = useMemo(() => {
    return _.compact(
      data.selectedSignals.map((signal) => _.head(signal.group?.nodeIds))
    );
  }, [data.selectedSignals]);

  const nodes = useNodes(nodeIds);

  const _exportData = useCallback(() => {
    const signalExportMappings = data.selectedSignals.map((signal) => {
      return {
        signalId: signal.item.signalId,
        displayName:
          getNodeName(signal.group, nodes.nodes, nodes.parents, ' / ') +
          ' / ' +
          signal.group.signalProviderName +
          ' / ' +
          signal.item.name
      };
    });

    const region = new Intl.DateTimeFormat();

    const exportedData = {
      signalExportMappings,
      startDate: formatDate(data.dateFrom),
      endDate: formatDate(data.dateTo),
      samplingInterval: data.sampling,
      aggregationType: data.aggregation,
      exportFormat: data.format as unknown as ExportFormat,
      timeZone: region.resolvedOptions().timeZone
    };

    exportMutation.mutate([exportedData]);
  }, [
    data.selectedSignals,
    data.dateFrom,
    data.dateTo,
    data.sampling,
    data.aggregation,
    data.format,
    exportMutation,
    nodes.nodes,
    nodes.parents
  ]);

  return (
    <ActionModal
      compact
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={exportMutation.isPending}
      headerIcon={Icons.Download}
      title={T.exportpage.title}
      actionText={T.exportpage.title}
      onConfirmClick={_exportData}
    >
      {T.exportpage.confirmexport}
    </ActionModal>
  );
};

export default ConfirmExportSignalsDialog;
