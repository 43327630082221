/* Format:
 * day: starts at index 1
 * start: The hour when schedule starts
 * duration: Number of hours to run
 * globalAmplitude: set global amplitude to this value
 */
import { ScheduleSourceTypeIds } from 'js/components/EMPTools/PowerControl/Dispatch/utils/scheduleConstants';
import { SchedulePresetType } from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleModal';
import { Moment } from 'moment';
import { AddOrUpdatePowerControlScheduleRequestModel } from 'ecto-common/lib/API/APIGen';

export type SchedulePresetTypeSchedule = {
  day: number;
  start: number;
  duration: number;
  globalAmplitude: number;
};

const testSchedulesOrebro = [
  { day: 1, start: 7, duration: 1, globalAmplitude: -100 },
  { day: 1, start: 8, duration: 1, globalAmplitude: 100 },
  { day: 2, start: 6, duration: 1, globalAmplitude: 100 },
  { day: 2, start: 7, duration: 2, globalAmplitude: -100 },
  { day: 3, start: 0, duration: 2, globalAmplitude: 50 },
  { day: 3, start: 2, duration: 5, globalAmplitude: -50 },
  { day: 3, start: 7, duration: 5, globalAmplitude: 0 }
];

const testSchedulesOther = [
  { day: 1, start: 9, duration: 1, globalAmplitude: -50 },
  { day: 2, start: 9, duration: 1, globalAmplitude: -50 },
  { day: 2, start: 10, duration: 1, globalAmplitude: 50 },
  { day: 3, start: 1, duration: 1, globalAmplitude: -100 },
  { day: 5, start: 1, duration: 3, globalAmplitude: -50 },
  { day: 6, start: 23, duration: 2, globalAmplitude: 25 },
  { day: 7, start: 1, duration: 5, globalAmplitude: -25 }
];

export const testPresetSchedules: SchedulePresetType[] = [
  {
    name: 'ÖREBRO',
    startOffset: 0,
    isPowerControlTest: true,
    schedules: testSchedulesOrebro
  },
  {
    name: 'TEST',
    startOffset: 0,
    isPowerControlTest: true,
    schedules: testSchedulesOther
  }
];

const startDateOfSchedule = (
  schedule: SchedulePresetTypeSchedule,
  startDate: Moment
) => {
  return startDate
    .clone()
    .add(schedule.day - 1, 'days')
    .add(schedule.start, 'hours');
};

export function createSchedules(
  scheduleList: SchedulePresetTypeSchedule[],
  startDate: Moment
): AddOrUpdatePowerControlScheduleRequestModel[] {
  return scheduleList.map((schedule) => {
    const scheduleStart = startDateOfSchedule(schedule, startDate);
    return {
      id: undefined as string,
      signalProviderId: undefined as string,
      startDate: scheduleStart.toISOString(),
      endDate: scheduleStart
        .clone()
        .add(schedule.duration, 'hours')
        .toISOString(),
      globalAmplitude: schedule.globalAmplitude,
      desiredEmergency: false,
      desiredAcuteEmergency: false,
      sourceTypeId: ScheduleSourceTypeIds.MANUAL
    };
  });
}

// Find minimum starting date for schedules
export function firstStartDate(
  scheduleList: SchedulePresetTypeSchedule[],
  startDate: Moment
) {
  const startOfDay = startDate.clone().startOf('day');
  return scheduleList.reduce((currentEarliestDate, schedule) => {
    const scheduleStart = startDateOfSchedule(schedule, startOfDay);
    // No earlier date or current schedule starts earlier, then this schedule has the earliest date
    if (
      currentEarliestDate == null ||
      scheduleStart.isSameOrBefore(currentEarliestDate)
    ) {
      return scheduleStart;
    }
    return currentEarliestDate;
  }, null);
}
