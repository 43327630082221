import _ from 'lodash';
import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import RequestModule from 'ecto-common/lib/RequestModule/RequestModule';
import {
  getInitialReqState,
  initialReqState,
  mapReqStateToProp,
  REQ_STATE_CANCELLED,
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  RequestStatusProp,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import APIGen, {
  GetSignalsByNodeRequestModel,
  PowerControlGetPowerControlScheduleParams,
  PowerControlType,
  ScheduleCollectionResponseModel,
  SignalProviderByNodeResponseModel,
  SignalProviderTelemetryResponseModel,
  SignalProviderTelemetryValueResponseModel,
  SignalProviderType,
  SignalResponseModel,
  SignalsGetSignalValuesParams
} from 'ecto-common/lib/API/APIGen';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import { OperatorDispatch, OperatorGetState } from 'js/reducers/storeOperator';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AnnotatedScheduleSignal } from 'js/components/EMPTools/PowerControl/Dispatch/PowerControlSignalChart';

const FORTY_EIGHT_HOURS = 48 * 60 * 60 * 1000;

const SET_TELEMETRY_DATA = 'SCHEDULE/SET_TELEMETRY_DATA';
const GET_SIGNALS = 'SCHEDULE/GET_SIGNALS';
const SET_SELECTED_SIGNALS = 'SCHEDULE/SET_SELECTED_SIGNALS';
const SET_CHART_NODE_ID = 'SCHEDULE/SET_CHART_NODE_ID';

const GET_SIGNAL_VALUES = 'SCHEDULE/GET_SIGNAL_VALUES';
const GET_SIGNAL_FORECAST_VALUES = 'SCHEDULE/GET_SIGNAL_FORECAST_VALUES';

const GET_SCHEDULES = 'SCHEDULE/GET_SCHEDULES';
const CLEAR_STEER_SIGNAL_VALUES = 'CLEAR_STEER_SIGNAL_VALUES';

const RESET_STATE = 'SCHEDULE/RESET_STATE';

const SET_REFRESHING_SCHEDULES = 'SCHEDULE/SET_REFRESHING_SCHEDULES';

const requests = {
  [GET_SCHEDULES]: 'schedules',
  [GET_SIGNALS]: 'signals',
  [GET_SIGNAL_VALUES]: 'signalValues',
  [GET_SIGNAL_FORECAST_VALUES]: 'signalForecastValues'
};

export type ScheduleSignal = {
  signalProviderName: string;
  groupTypeId: string;
  signal: SignalResponseModel;
};

type ScheduleReducerState = {
  // For EffectSummary
  steerSignalValues: SignalProviderTelemetryValueResponseModel[];
  // For PowerControlSignalChart
  chartTelemetryData: SignalProviderTelemetryResponseModel[];
  chartTelemetryError: boolean;
  selectedSignals: ScheduleSignal[];
  hasValidSignals: boolean;
  chartNodeId: string;
  refreshingSchedules: boolean;
  schedules: RequestStatusProp<ScheduleCollectionResponseModel>;
  signals: RequestStatusRawProp<SignalProviderByNodeResponseModel[]>;
  signalValues: RequestStatusRawProp<SignalProviderTelemetryResponseModel[]>;
  signalForecastValues: RequestStatusRawProp<
    SignalProviderTelemetryResponseModel[]
  >;
};

const initialState: ScheduleReducerState = {
  // For EffectSummary
  steerSignalValues: [],
  // For PowerControlSignalChart
  chartTelemetryData: null,
  chartTelemetryError: false,
  selectedSignals: null,
  hasValidSignals: true,
  chartNodeId: null,
  refreshingSchedules: false,
  schedules:
    mapReqStateToProp<ScheduleCollectionResponseModel>(initialReqState),
  signals: getInitialReqState<SignalProviderByNodeResponseModel[]>(),
  signalValues: getInitialReqState<SignalProviderTelemetryResponseModel[]>(),
  signalForecastValues:
    getInitialReqState<SignalProviderTelemetryResponseModel[]>()
};

const createFullInitialState = (): ScheduleReducerState => {
  return {
    ...module.initialState,
    ...initialState
  };
};

const module = new RequestModule(requests);

export default createReducer(createFullInitialState(), {
  ...module.handlers,
  [GET_SCHEDULES]: (state, action) => {
    if (action.state === REQ_STATE_CANCELLED) {
      return state;
    }

    let refreshingSchedules = state.refreshingSchedules;

    if (action.state !== REQ_STATE_PENDING) {
      refreshingSchedules = false;
    }

    return {
      ...state,
      schedules: mapReqStateToProp(action),
      refreshingSchedules
    };
  },
  [GET_SIGNAL_FORECAST_VALUES]: (state, action) => {
    // When forecast signal values changes, we need to update effect summary
    let ret = state;
    if (action.state === REQ_STATE_SUCCESS) {
      const results = action.payload;

      if (results.length === 1) {
        ret = { ...state, steerSignalValues: results[0].signals };
      }
    }

    return module.handlers[GET_SIGNAL_FORECAST_VALUES](ret, action);
  },
  [GET_SIGNAL_VALUES]: (state, action) => {
    let ret = state;
    if (action.state === REQ_STATE_SUCCESS) {
      const results = action.payload;
      ret = {
        ...state,
        chartTelemetryData: _.cloneDeep(results),
        chartTelemetryError: false
      };
    } else if (action.state === REQ_STATE_ERROR) {
      ret = {
        ...state,
        chartTelemetryData: [],
        chartTelemetryError: true
      };
    }

    return module.handlers[GET_SIGNAL_VALUES](ret, action);
  },
  [SET_TELEMETRY_DATA]: (state, { chartTelemetryData }) => {
    return { ...state, chartTelemetryData };
  },
  [SET_SELECTED_SIGNALS]: (state, { selectedSignals }) => {
    return { ...state, selectedSignals };
  },
  [SET_CHART_NODE_ID]: (state, { chartNodeId }) => {
    return { ...state, chartNodeId };
  },
  [CLEAR_STEER_SIGNAL_VALUES]: (state) => {
    return {
      ...state,
      steerSignalValues: [] as SignalProviderTelemetryValueResponseModel[]
    };
  },
  [RESET_STATE]: () => {
    return createFullInitialState();
  },
  [SET_REFRESHING_SCHEDULES]: (state, { refreshingSchedules }) => {
    return { ...state, refreshingSchedules };
  }
});

const getForecastSignalData = (
  contextSettings: ApiContextSettings,
  dispatch: OperatorDispatch,
  selectedSignals: AnnotatedScheduleSignal[]
) => {
  const steerSignal = selectedSignals.find(
    (s) =>
      s.signal.signalTypeId === SignalTypeIds.STEERABLE_POWER_SIGNAL_TYPE_ID
  );

  if (steerSignal) {
    const forecastDateFrom = new Date().getTime();
    const forecastDateTo = forecastDateFrom + FORTY_EIGHT_HOURS;

    const args: SignalsGetSignalValuesParams = {
      SignalIds: [steerSignal.signal.signalId],
      StartDate: new Date(forecastDateFrom).toISOString(),
      EndDate: new Date(forecastDateTo).toISOString(),
      Points: 48
    };

    dispatch(
      module.doRequest(
        contextSettings,
        GET_SIGNAL_FORECAST_VALUES,
        APIGen.Signals.getSignalValues.promise,
        args
      )
    );
  }
};

const getToolsSignalData = (
  contextSettings: ApiContextSettings,
  dispatch: OperatorDispatch,
  selectedSignals: ScheduleSignal[],
  dateFrom: number,
  dateTo: number,
  points: number
) => {
  if (
    selectedSignals != null &&
    selectedSignals.length > 0 &&
    dateFrom !== -1 &&
    dateTo !== -1
  ) {
    const signalIds = _.map(selectedSignals, 'signal.signalId');

    const args: SignalsGetSignalValuesParams = {
      SignalIds: signalIds,
      StartDate: new Date(dateFrom).toISOString(),
      EndDate: new Date(dateTo).toISOString(),
      Points: Math.floor(points) ?? 500
    };

    dispatch(
      module.doRequest(
        contextSettings,
        GET_SIGNAL_VALUES,
        APIGen.Signals.getSignalValues.promise,
        args
      )
    );
  } else {
    dispatch(ScheduleActions.setChartTelemetryData([]));
  }
};

export const ScheduleActions = {
  cancelGetSchedules: () => {
    return (dispatch: OperatorDispatch) => {
      dispatch({
        type: SET_REFRESHING_SCHEDULES,
        payload: { refreshingSchedules: false }
      });
      dispatch(module.cancelRequest(GET_SCHEDULES));
    };
  },
  getSchedules: (
    contextSettings: ApiContextSettings,
    nodeId: string,
    count: number,
    isRefresh = false
  ) => {
    return (dispatch: OperatorDispatch) => {
      dispatch({
        type: SET_REFRESHING_SCHEDULES,
        payload: { refreshingSchedules: isRefresh }
      });

      const args: PowerControlGetPowerControlScheduleParams = {
        PowerControlType: PowerControlType.Heating,
        NodeIds: [nodeId],
        NumberOfHistoricalSchedules: count
      };

      dispatch(
        module.doRequest(
          contextSettings,
          GET_SCHEDULES,
          APIGen.PowerControl.getPowerControlSchedule.promise,
          args
        )
      );
    };
  },
  setChartTelemetryData: createAction(SET_TELEMETRY_DATA, 'chartTelemetryData'),
  setSelectedSignals: createAction(SET_SELECTED_SIGNALS, 'selectedSignals'),
  setChartNodeId: createAction(SET_CHART_NODE_ID, 'chartNodeId'),
  resetState: createAction(RESET_STATE),
  getSignals: (contextSettings: ApiContextSettings, nodeId: string) => {
    return async (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      dispatch(module.cancelRequest(GET_SIGNALS));
      dispatch(module.cancelRequest(GET_SIGNAL_VALUES));
      dispatch(module.cancelRequest(GET_SIGNAL_FORECAST_VALUES));
      dispatch(ScheduleActions.setChartTelemetryData([]));
      dispatch(ScheduleActions.setSelectedSignals(null));
      dispatch(ScheduleActions.setChartNodeId(nodeId));

      const args: GetSignalsByNodeRequestModel = {
        nodesIds: [nodeId]
      };

      const res = await dispatch(
        module.doRequest(
          contextSettings,
          GET_SIGNALS,
          APIGen.Signals.getSignalsByNode.promise,
          args
        )
      );

      if (res === undefined) {
        return;
      }

      const { schedule } = getState();
      const signals = schedule.signals.payload;
      const defaultSignals = getDefaultSignals(signals);

      if (defaultSignals.length === 0) {
        dispatch(ScheduleActions.setChartTelemetryData([]));
      }

      dispatch(ScheduleActions.setSelectedSignals(defaultSignals));
    };
  },
  updateChartData: (
    contextSettings: ApiContextSettings,
    dateFrom: number,
    dateTo: number,
    points: number
  ) => {
    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      const { schedule } = getState();
      const { selectedSignals } = schedule;
      getToolsSignalData(
        contextSettings,
        dispatch,
        selectedSignals,
        dateFrom,
        dateTo,
        points
      );
    };
  },
  getSteerSignalValues: (
    contextSettings: ApiContextSettings,
    selectedSignals: AnnotatedScheduleSignal[]
  ) => {
    return (dispatch: OperatorDispatch) => {
      getForecastSignalData(contextSettings, dispatch, selectedSignals);
    };
  },
  clearSteerSignalValues: () => (dispatch: OperatorDispatch) =>
    dispatch({ type: CLEAR_STEER_SIGNAL_VALUES })
};

const getDefaultSignals = (
  data: SignalProviderByNodeResponseModel[]
): ScheduleSignal[] => {
  if (_.isEmpty(data)) {
    return [];
  }

  const DEFAULT_SIGNALS: string[] = [
    SignalTypeIds.AIR_TEMPERATURE_SIGNAL_TYPE_ID
  ];

  // Hard-code stuff to select two signals
  const findMeteoGroup = data.find((s) =>
    s.signalProviderType.includes(SignalProviderType.Meteorology)
  );
  const findSteerablePowerGroup = data.find((s) =>
    s.signalProviderType.includes(SignalProviderType.SteerablePower)
  );
  let defaultSignals: ScheduleSignal[] = [];
  if (findMeteoGroup) {
    const hardcodedMeteoSignals = findMeteoGroup.signals
      .filter((s) => DEFAULT_SIGNALS.includes(s.signalTypeId))
      .map((s) => {
        return {
          signalProviderName: findMeteoGroup.signalProviderName,
          groupTypeId: findMeteoGroup.signalProviderId,
          signal: s
        };
      });
    defaultSignals = [...defaultSignals, ...hardcodedMeteoSignals];
  }
  if (findSteerablePowerGroup) {
    const hardcodedSteerableSignals = findSteerablePowerGroup.signals.map(
      (s) => {
        return {
          signalProviderName: findSteerablePowerGroup.signalProviderName,
          groupTypeId: findSteerablePowerGroup.signalProviderId,
          signal: s
        };
      }
    );

    defaultSignals = [...defaultSignals, ...hardcodedSteerableSignals];
  }

  return defaultSignals;
};
