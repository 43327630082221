import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import EctoplannerAPIGen, {
  BuildResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import {
  EctoplannerForm,
  EctoplannerTimeSeries,
  SecosimForm
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import T from 'ecto-common/lib/lang/Language';
import { TreeViewNodeType } from 'ecto-common/lib/TreeView/TreeViewNode';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { stripCalculationData } from 'js/components/Ectoplanner/EctoplannerResultType';
import {
  EctoplannerBuildStatus,
  EctoplannerProjectTypes,
  ectoplannerBuildTimedOut,
  isEctoplannerStatusInProgress
} from 'js/components/Ectoplanner/EctoplannerTypes';
import { ectoplannerMasterBuildId } from 'js/utils/routeConstants';
import _ from 'lodash';
import { useMemo, useState } from 'react';

export const numberAtEndRegex = /([0-9]+)$/m;

export type EctoplannerSaveFormData =
  | {
      projectType: typeof EctoplannerProjectTypes.Ectoplanner;
      data: EctoplannerForm;
    }
  | {
      projectType: typeof EctoplannerProjectTypes.Secosim;
      data: SecosimForm;
    };

export const saveEctoplannerFormPromise = ({
  contextSettings,
  projectId,
  buildId,
  formData,
  lastWeatherStationId
}: {
  buildId: string;
  projectId: string;
  formData: EctoplannerSaveFormData;
  lastWeatherStationId: string;
  contextSettings: ApiContextSettings;
}): Promise<unknown> => {
  let formBody: string = null;
  if (formData.projectType === EctoplannerProjectTypes.Ectoplanner) {
    formBody = JSON.stringify(stripCalculationData(formData.data));
  } else {
    formBody = JSON.stringify(formData.data);
  }

  const formPromise = buildIdIsNullOrMaster(buildId)
    ? EctoplannerAPIGen.EctoGridProjects.formdataUpdate.promise(
        contextSettings,
        {
          projectId
        },
        formBody,
        null
      )
    : EctoplannerAPIGen.EctoGridProjects.buildsFormdataUpdate.promise(
        contextSettings,
        {
          buildId,
          projectId
        },
        formBody,
        null
      );

  if (
    lastWeatherStationId !== formData.data?.location?.city?.id &&
    formData.data?.location?.city?.id != null &&
    buildIdIsNullOrMaster(buildId)
  ) {
    return Promise.all([
      formPromise,
      EctoplannerAPIGen.EctoGridProjects.weatherCreate.promise(
        contextSettings,
        {
          projectId
        },
        {
          weatherStationId: formData.data.location.city.id
        },
        null
      )
    ] as const);
  }

  return formPromise;
};

export const buildIdIsNullOrMaster = (buildId: string) => {
  return buildId == null || buildId === ectoplannerMasterBuildId;
};

export const saveAndBuildPromise = ({
  contextSettings,
  projectId,
  buildId,
  formData,
  hasChanges,
  lastWeatherStationId,
  checksum
}: {
  projectId: string;
  buildId: string;
  formData: EctoplannerSaveFormData;
  hasChanges: boolean;
  lastWeatherStationId: string;
  contextSettings: ApiContextSettings;
  checksum: string;
}) => {
  const doBuildPromise = () => {
    if (buildIdIsNullOrMaster(buildId)) {
      return EctoplannerAPIGen.EctoGridProjects.buildsMasterBuildCreate.promise(
        contextSettings,
        {
          projectId
        },
        {
          Checksum: checksum
        },
        null
      );
    }

    return EctoplannerAPIGen.EctoGridProjects.buildsRecalculateCurrentVersionCreate.promise(
      contextSettings,
      {
        projectId,
        buildId
      },
      {
        Checksum: checksum
      },
      null
    );
  };

  if (hasChanges) {
    return saveEctoplannerFormPromise({
      contextSettings,
      projectId,
      buildId,
      formData,
      lastWeatherStationId
    }).then(() => {
      return doBuildPromise();
    });
  }

  return doBuildPromise();
};

export const ectoplannerCalculationIsLoading = (
  selectedBuild: BuildResponse
) => {
  let statusInProgress = false;
  let timedOutProgress = false;

  if (selectedBuild != null) {
    const buildStatus = selectedBuild.status as EctoplannerBuildStatus;
    statusInProgress = isEctoplannerStatusInProgress(buildStatus);
    timedOutProgress =
      statusInProgress && ectoplannerBuildTimedOut(selectedBuild);
  }
  return statusInProgress && !timedOutProgress;
};

export type TimeSeriesCategory = {
  name: string;
  timeseries: EctoplannerTimeSeries[];
};

export const useTimeseriesTreeViewNodes = ({
  timeseries
}: {
  timeseries: EctoplannerTimeSeries[];
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const categories: TimeSeriesCategory[] = useMemo(() => {
    const groups = _.groupBy(timeseries, (series) => {
      if (series.asset_name != null) {
        return series.building_name + ' - ' + series.asset_name;
      } else if (series.building_name != null) {
        return series.building_name;
      }

      return series.category;
    });
    return sortByLocaleCompare(Object.entries(groups), '[0]').map(
      ([name, nestedTimeseries]) => {
        return {
          name: name,
          timeseries: nestedTimeseries
        };
      }
    );
  }, [timeseries]);

  const nodes: TreeViewNodeType[] = useMemo(() => {
    const buildingNodes = categories.filter(
      (category) =>
        category.timeseries[0]?.building_name != null &&
        category.timeseries[0]?.asset_name == null
    );
    const rootNodes = categories.filter(
      (category) => category.timeseries[0]?.building_name == null
    );

    const grouped = Object.entries(
      _.groupBy(rootNodes, (category) => {
        return category.timeseries[0]?.folder ?? 'root';
      })
    );

    const nested = grouped.filter((x) => x[0] !== 'root');
    const rootElems = grouped.find((x) => x[0] === 'root')?.[1] ?? [];

    return _.orderBy(
      _.compact([
        buildingNodes.length > 0 && {
          id: 'buildings',
          name: T.ectoplanner.form.shared.buildings,
          children: buildingNodes.map((category) => ({
            id: category.name,
            name: category.name,
            path: category.name,
            children: categories
              .filter(
                (nestedCategory) =>
                  nestedCategory.timeseries[0]?.building_name ===
                    category.timeseries[0]?.building_name &&
                  nestedCategory.timeseries[0]?.asset_name != null
              )
              .map((nestedCategory) => ({
                name: nestedCategory.name,
                id: nestedCategory.name,
                path: nestedCategory.name,
                children: [] as TreeViewNodeType[]
              }))
          })),
          path: 'buildings'
        },
        ...nested.map(([folder, nestedCategories]) => {
          return {
            id: folder,
            name: folder,
            path: folder,
            children: nestedCategories.map((category) => ({
              id: category.name,
              name: category.name,
              path: category.name,
              children: [] as TreeViewNodeType[]
            }))
          };
        }),
        ...rootElems.map((category) => ({
          id: category.name,
          name: category.name,
          path: category.name,
          children: [] as TreeViewNodeType[]
        }))
      ]),
      'name'
    );
  }, [categories]);

  if (nodes.length > 0 && selectedId == null) {
    const firstRootElement = _.find(
      nodes,
      (node) => node.children.length === 0
    );
    if (firstRootElement != null) {
      setSelectedId(firstRootElement?.name);
    }
  }

  const selectedNodes = useMemo(() => {
    const ret: Record<string, boolean> = {};
    if (selectedId) {
      ret[selectedId] = true;
    }
    return ret;
  }, [selectedId]);
  const currentCategory = categories.find((x) => x.name === selectedId);

  return [nodes, selectedNodes, setSelectedId, currentCategory] as const;
};

export const defaultGetEctoplannerBuildArgs = {
  $top: 1000
};
